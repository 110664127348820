<div class="container">
  <!-- TradingView Widget BEGIN -->
  <div class="tradingview-widget-container">
    <div id="tradingview_708ae"></div>
    <div class="tradingview-widget-copyright">
      <a
        href="https://www.tradingview.com/symbols/NASDAQ-AAPL/"
        rel="noopener"
        target="_blank"
        ><span class="blue-text">AAPL stock chart</span></a
      >
      by TradingView
    </div>
    <script
      type="text/javascript"
      src="https://s3.tradingview.com/tv.js"
    ></script>
    <script type="text/javascript">
      new TradingView.widget({
        autosize: true,
        symbol: "NASDAQ:AAPL",
        interval: "D",
        timezone: "Etc/UTC",
        theme: "dark",
        style: "0",
        locale: "en",
        toolbar_bg: "#f1f3f6",
        enable_publishing: false,
        withdateranges: true,
        allow_symbol_change: true,
        save_image: false,
        container_id: "tradingview_708ae",
      });
    </script>
  </div>
  <!-- TradingView Widget END -->
</div>
