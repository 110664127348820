<div class="container main">
  <div class="row">
    <div class="col-md-8 mx-auto">
      <div class="card-group">
        <div class="card p-4 main">
          <div class="card-body">
            <ng-container>
              <form #authForm="ngForm" (ngSubmit)="login(authForm)">
                <h1>Login</h1>
                <p class="text-muted">Sign In to your account</p>
                <div class="input-group mb-3">
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    placeholder="User email"
                    ngModel
                    name="email"
                    required
                    email
                  />
                </div>
                <div class="input-group mb-4">
                  <input
                    type="password"
                    id="password"
                    class="form-control"
                    placeholder="Password"
                    ngModel
                    name="password"
                    required
                    minlength="6"
                  />
                </div>
                <div class="row">
                  <div class="col-12">
                    <button
                      type="submit"
                      class="btn btn-primary px-4 btn-block"
                      [disabled]="!authForm.valid"
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div class="clearfix"></div>

                <div class="separator"></div>
              </form>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
