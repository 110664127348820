<header class="black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav" id="mh-header">
    <div class="overlay"></div>
    <div class="container">
        <div class="row">
            <nav class="navbar navbar-expand-lg mh-nav nav-btn">
                <a class="navbar-brand">
                    <img src="./assets/images/logo.png" alt="Amr Gharieb" class="img-fluid" style="width: 60px;">
                    <!-- <h2>Maha</h2> -->
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span
                        class="navbar-toggler-icon icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-0 ml-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#mh-home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#mh-about">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#mh-service">Services</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#mh-pricing">Clients</a>
                        </li>
                        <li class="dropdown">
                            <a class="nav-link dropdown-toggle" 
                             data-toggle="dropdown">Articles
                            <span class="caret"></span></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a class="nav-link reset" href="#mh-blog">Recent</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link reset" routerLink="/posts">All</a>
                                </li>


                            </ul>
                          </li>
                        
                          <li class="nav-item">
                            <a class="nav-link" *ngIf="!(user$ |async)" (click)="login()">Login</a>
                        </li>

                        <li class="dropdown" *ngIf="user$ |async">
                            <a class="nav-link dropdown-toggle" 
                             data-toggle="dropdown">Admin Panel
                            <span class="caret"></span></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a class="nav-link reset" routerLink="/addPost">New Post</a>
                                </li>
                                <hr>
                                <li class="nav-item">
                                    <a class="nav-link reset" (click)="logOut()">Logout</a>
                                </li>

                            </ul>
                          </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#mh-projects">Projects</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#mh-skills">Skills</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#mh-experience">Experiences</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#mh-blog">Articles</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#mh-testimonial">Testimonials</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#mh-contact">Contact</a>
                        </li> -->
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>
