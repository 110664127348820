import { HttpClient } from "@angular/common/http";
import {
  Component,
  forwardRef,
  OnInit,
  Pipe,
  PipeTransform,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/Firestore";
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { NgForm, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import FroalaEditor from "froala-editor";
import * as Quill from "node_modules/quill/dist/quill.js";
import ImageResize from "quill-image-resize";
import { PostService } from "./post.service";
Quill.register("modules/imageResize", ImageResize);
import { catchError, finalize, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Post } from "../models/Post.model";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../Authentication/login/auth.service";
import { AppUser } from "../../Authentication/login/user.model";

@Component({
  selector: "app-add-post",
  templateUrl: "./add-post.component.html",
  styleUrls: ["./add-post.component.css"],
})
export class AddPostComponent implements OnInit {
  content;
  options;
  editor;
  button = {
    moreText: {
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "fontSize",
        "textColor",
        "backgroundColor",
        "inlineClass",
        "inlineStyle",
        "clearFormatting",
      ],
      buttonsVisible: 3,
    },
    moreParagraph: {
      buttons: [
        "alignLeft",
        "alignCenter",
        "alignRight",
        "alignJustify",
        "outdent",
        "indent",
        "formatOL",
        "formatUL",
        "paragraphFormat",
        "paragraphStyle",
        "quote",
      ],
      buttonsVisible: 6,
    },
    moreRich: {
      buttons: [
        "insertLink",
        "insertImage",
        "insertVideo",
        "insertTable",
        "emoticons",
        "specialCharacters",
        "insertHR",
      ],
      buttonsVisible: 4,
    },
    fullScreen: {
      buttons: ["fullscreen"],
    },
    moreMisc: {
      buttons: [
        "undo",
        "redo",
        "fullscreen",
        "print",
        "getPDF",
        "selectAll",
        "html",
        "help",
        "save",
      ],
      align: "right",
      buttonsVisible: 2,
    },
  };
  user: AppUser;
  post: Post = {};
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  featuredImagePercent: Observable<number>;
  featuredImagedownloadURL: Observable<string>;
  id: string;

  @ViewChild("progressWindow", { static: false }) private progressWindow: any;
  @ViewChild("success", { static: false }) private success: any;
  @ViewChild("fail", { static: false }) private fail: any;

  constructor(
    public sanitized: DomSanitizer,
    private http: HttpClient,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private service: PostService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.authService.appUsers.subscribe((x: AppUser) => {
      this.user = x;
    });

    this.id = this.route.snapshot.paramMap.get("id");
    const self = this;

    this.options = {
    key: "1C%kZV[IX)_SL}UJHAEFZMUJOYGYQE[\\ZJ]RAe(+%$==",
    attribution: false,
    height: 350,
    charCounterCount: false,
    toolbarButtons: this.button,
    toolbarButtonsMD: this.button,
    toolbarButtonsXS: this.button,
    toolbarButtonsSM: this.button,
    toolbarTop: true,
    content: this.content,
    language: "en",
    //  // Set the image upload parameter.

    requestHeaders: {
      Authorization: "Basic " + btoa("Htuslvida:UY*/-jkdr*fhg"),
    },

    //imageUploadURL: `${environment.KMSAPI}Upload/UploadImages`,
    imageUpload: true,
    imageUploadMethod: "POST",
    imageMaxSize: 100 * 1024 * 1024,
    //imageAllowedTypes: ["jpeg", "jpg", "png"],

    // videoUploadURL: `${environment.KMSAPI}Upload/UploadVideos`,
    // videoUpload: true,
    // videoUploadMethod: "POST",
    // videoMaxSize: 200 * 1024 * 1024,

    // fileUploadURL: `${environment.KMSAPI}Upload/UploadFiles`,
    // fileUpload: true,
    // fileUploadMethod: "POST",
    // fileMaxSize: 200 * 1024 * 1024,

    fontFamily: {
      "Arial,Helvetica,sans-serif": "Arial",
      "'Courier New',Courier,monospace": "Courier New",
      "Georgia,serif": "Georgia",
      "Impact,Charcoal,sans-serif": "Impact",
      "'Lucida Console',Monaco,monospace": "Lucida Console",
      "Tahoma,Geneva,sans-serif": "Tahoma",
      "'Times New Roman',Times,serif": "Times New Roman",
      "Verdana,Geneva,sans-serif": "Verdana",
    },
    events: {
      initialized: (e) => {
        this.editor = e.getEditor();
      },
      "image.uploaded": (response) => {
        let imageLink =
        //  environment.hardFilesPath +
          JSON.parse(response)["link"].replace(/\\/g, "/");
        this.editor.image.insert(
          imageLink,
          false,
          null,
          this.editor.image.get(),
          { link: imageLink }
        );
        return false;
      },
      // "video.uploaded": (response) => {
      //   let videoLink =
      //     environment.hardFilesPath +
      //     JSON.parse(response)["link"].replace(/\\/g, "/");
      //   // this.editor.video.insert(`<p>
      //   //               <span class="fr-video fr-dvb fr-draggable" contenteditable="false" draggable="true">
      //   //               <video class="fr-draggable" controls="" src="${videoLink}" style="width: 600px;">
      //   //               </video></span></p>`);


      //                 this.content += `<p>
      //                 <span class="fr-video fr-dvb fr-draggable" contenteditable="false" draggable="true">
      //                 <video class="fr-draggable" controls="" src="${videoLink}" style="width: 600px;">
      //                 </video></span></p>`
      //   this.editor.video.insert(`<hr>`);
      //   return false;
      // },
      // "file.uploaded": (response) => {
      //   let fileLink =
      //     environment.hardFilesPath +
      //     JSON.parse(response)["link"].replace(/\\/g, "/");
      //   this.editor.file.insert(fileLink, JSON.parse(response)["fileName"], {
      //     link: fileLink,
      //   });

      //   return false;
      // },

      "image.beforeUpload": async (images) => {
        // let formData = new FormData();
        // formData.set("file", images[0], images[0].name);
        // const endpoint = `${environment.KMSAPI}Upload/UploadImages`;
        // let image: any = await this.http
        //   .post(endpoint, formData, {
        //     headers: environment.UploadhttpOption,
        //     observe: "events",
        //     reportProgress: true,
        //   })
        //   .toPromise();
        // let imageObj = image.body;
        // let imageUrl = environment.activityPath +  imageObj.link.replace(/\\/g, "/");
        // this.editor.image.insert(imageUrl, null, null, this.editor.image.get(),null);
        // return false;
      },
    },
  };
    let ctx = document.getElementById("editor");
    this.editor = new FroalaEditor(ctx, this.options);

    if (this.id) {
      return this.service.getPost(this.id).subscribe((x: Post) => {
        this.post = x;
        this.editor.html.set(x.post);
      });
    }
  }

  save(formData: NgForm) {
    if (
      this.post.title == "" ||
      this.post.title == undefined ||
      this.post.title == null
    )
      return alert("Please write the title of the post you are creating");

    if (
      this.post.category == "" ||
      this.post.category == undefined ||
      this.post.category == null
    )
      return alert("Please choose the category of the post you are creating");

    if (
      this.post.featuredImage == "" ||
      this.post.featuredImage == undefined ||
      this.post.featuredImage == null
    )
      return alert("Please upload the featured image");

    this.service
      .savePost({
        title: this.post.title,
        category: this.post.category,
        post: document.getElementById('post').innerHTML,
        createdOn: -1 * new Date().getTime(),
        lastModified: new Date().getTime(),
        featuredImage: this.post.featuredImage,
        date: new Date().getTime(),
        creator:this.user.userName
      })
      .then((data) => {
        this.openProgressWindow(this.success);
        this.router.navigate(["/posts"]);
      })
      .catch((err) => {
        console.log(err);
        this.openProgressWindow(this.fail);
      });
  }

  update(formData: NgForm) {
    if (
      this.post.title == "" ||
      this.post.title == undefined ||
      this.post.title == null
    )
      return alert("Please write the title of the post you are creating");

    if (
      this.post.category == "" ||
      this.post.category == undefined ||
      this.post.category == null
    )
      return alert("Please choose the category of the post you are creating");

    if (
      this.post.featuredImage == "" ||
      this.post.featuredImage == undefined ||
      this.post.featuredImage == null
    )
      return alert("Please upload the featured image");

    this.service
      .update(
        {
          title: this.post.title,
          category: this.post.category,
          post: document.getElementById('post').innerHTML,
          lastModified: new Date().getTime(),
          featuredImage: this.post.featuredImage,
          modifier:this.user.userName
        },
        this.id
      )
      .then(() => {
        this.openProgressWindow(this.success);
        this.router.navigate(["/posts"]);
      })
      .catch((err) => {
        console.log(err);
        this.openProgressWindow(this.fail);
      });
  }

  onFeaturedImageSelect(event) {
    if (event.target.files[0]) {
      const file = event.target.files[0];

      // Create a File Reader.
      const reader = new FileReader();

      // Set the reader to insert images when they are loaded.
      reader.onload = async (eLoad) => {
        const result = await (<any>eLoad.target).result;

        let time = new Date().getTime();
        let filePath =
          `featuredImages/F_${time}` +
          "." +
          result.split(",")[0].split(":")[1].split(";")[0].split("/")[1];
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(
          filePath,
          await this.service.dataUrlToFile(
            result,
            "_",
            result.split(",")[0].split(":")[1].split(";")[0]
          )
        );

        // observe percentage changes
        this.featuredImagePercent = task.percentageChanges();
        // get notified when the download URL is available
        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((x) => {
                this.post.featuredImage = x;
              });
            })
          )
          .subscribe();
      };

      // Read image as base64.
      reader.readAsDataURL(file);
    }
    // Stop default upload chain.
    return false;
  }

  editorChange() {


    this.post.post = this.editor.html.get()
    


    let images = document.querySelectorAll("#post img");
    for (let i = 0; i < images.length; i++) {  
      (<HTMLElement>images[i]).style.maxWidth='100%';
      
    }
    
    // this.post.post = this.sanitized.bypassSecurityTrustHtml(
    //   document.getElementById('post').innerHTML
    // );


  }

  openProgressWindow(screen) {
    this.modalService.open(screen, {
      size: "s",
      centered: true,
    });
  }
}
