<section class="mh-blog image-bg home-2-img" id="mh-blog">
    <div class="img-color-overlay">
        <div class="container">
            <div class="row section-separator">
                <div class="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                    <h3>Recent Articles</h3>
                </div>
               
                <div class="parent">
                    <div class="child" *ngFor="let post of posts |async">
                      <app-post [post]= "post" ></app-post>
          
                    </div>
                  </div>
                  
            </div>
        </div>
    </div>
</section>