import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectComponent implements OnInit {

@Input()tagName:string ;
@Input()projectName:string ;
@Input()brief:string ;
@Input()taxnomies:string;
@Input()image:string ;

  constructor() { }

  ngOnInit(): void {
  }

}
