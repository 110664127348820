
<div class="each-client-item">
    <div class="mh-client-item dark-bg black-shadow-1">
        <img src="http://cvresumetemplate.com/maha-personal-cv-resume-html-template/assets/images/c-1.png" alt="" class="img-fluid">
        <p>Absolute wonderful ! I am completely blown away.The very best.I was amazed at the quality
        </p>
        <h4>John Mike</h4>
        <span>CEO, Author.Inc</span>
    </div>
</div>

