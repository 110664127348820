import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppUser } from './user.model';
import { UserService } from './user.service';


export interface AuthResponseData {
  kind: string;
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;
}

export interface AuthChangePassResponseData {
  localId: string;
  email: string;
  passwordHash: string;
  providerUserInfo: [];
  idToken: string;
  refreshToken: string;
  expiresIn: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<firebase.User> = null;
  private tokenExpirationTimer: any;
  public expireIn = 4 * 3600 * 1000;
  apiKey: string = environment.firebaseConfig.apiKey;

  // constructor
  constructor(private http: HttpClient, private router: Router,
    private afAuth: AngularFireAuth , private userService:UserService) {
    this.user = afAuth.authState;

  }

  changePassword(email: string, oldPassword: string, newPassword: string) {
    const currentUser = firebase.auth().currentUser;
    const credentials = firebase.auth.EmailAuthProvider.credential(
      email, oldPassword);
    return currentUser.reauthenticateWithCredential(credentials).then(() => {
      return currentUser.updatePassword(newPassword);
    });
  }

  

  async confirmSignIn(url) {
    if (this.afAuth.isSignInWithEmailLink(url)) {
      let email = localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');

      }
      localStorage.removeItem('emailForSignIn');
      return await this.afAuth.signInWithEmailLink(email, url);
    }
  }

  // login with email and password
  login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password).then((user) => {
      let u = user.user;
      this.user = this.afAuth.authState;
      this.autoLogout(this.expireIn);
      localStorage.setItem('userData', JSON.stringify({ u }));
      this.userService.saveLogin(u.uid, u.email);
      this.router.navigate(['/']);

    });
  }


  autoLogin() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!userData) {
      return;
    }

    let n = new Date().getTime();
    let o = userData.u.lastLoginAt;
    let newSeconds = o - n + (this.expireIn);

    if (newSeconds < 0)
      this.autoLogout(newSeconds);

  }

  logout() {

    this.afAuth.signOut()
      .then((x) => {
        localStorage.removeItem('userData');
        localStorage.removeItem('xod');
        if (this.tokenExpirationTimer) {
          clearTimeout(this.tokenExpirationTimer);
        }
        this.tokenExpirationTimer = null;
        this.user = null;
        this.router.navigate(['/login']);
      }
      );
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  get appUsers(): Observable<AppUser> {
    return this.user.pipe(
      switchMap(user => {
        if (user) return this.userService.getUser(user.uid);
        return of(null);
      }));
  }



}

