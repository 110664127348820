<section style="margin-top: 80px">
  <div class="container">
    <div class="row section-separator">
      <div class="col-sm-12 col-md-12" *ngIf="post$ | async as post">
        <div style="width: 100%;display: flex;justify-content: center;" >
          <img [src]="post.featuredImage" alt="" style="border: 3px solid black;border-radius: 15px;" class="adjustedImg"/>
        </div>
        <br />
        <div>
          <h1 style="color: red;font-size: 2.5rem;line-height: 3rem;font-weight: 800;">
            {{ post.title }}
          </h1>
        </div>
        <br />
        <div style="display: flex; justify-content: start ; align-items: baseline;">
          <div class="mh-blog-post-info">
            <ul style="font-size: medium;">
              <li>
                <strong>Post On</strong
                ><a style="color: #23D3B7;">{{ post.date | date: "dd-MMM-yyyy" }}</a>
              </li>
              <li>
                <strong>By</strong><a style="color: #23D3B7;">{{ post.creator }}</a>
              </li>
            </ul>
          </div>
          <input
          *ngIf="user$ |async"
            type="button"
            class="btn btn-primary"
            [routerLink]="['/addPost', id]"
            value="Edit Post"
            style="font-size: x-small;"
          />
          <input
          *ngIf="user$ |async"
          type="button"
          class="btn btn-danger"
          (click)="deletePost()"
          value="Delete Post"
          style="font-size: x-small;"
        />
        </div>
        <br />

        <div [innerHtml]="sanitized.bypassSecurityTrustHtml(post.post)" style="line-height: 2.5rem;"></div>
      </div>
    </div>
  </div>
</section>
