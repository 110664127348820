import { Component, Input, OnInit, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { stripHtml } from "src/app/root/shared/functions";
import { AuthService } from "../../../Authentication/login/auth.service";
import { Post } from "../../models/Post.model";

@Component({
  selector: "app-post",
  templateUrl: "./post.component.html",
  styleUrls: ["./post.component.css"],
})
export class PostComponent implements OnInit {
  @Input("post") public post: Post = {};
  constructor(private sanitized: DomSanitizer) {}

  ngOnInit(): void {
    this.post.sanitizedPost = stripHtml(this.post.post).substring(0, 150);
  }
}
