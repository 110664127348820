// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebaseConfig : {
    apiKey: "AIzaSyCu6ab6po-MOthb6zi-abKefYAwLD8qVk8",
    authDomain: "amrgharieb-a9d6e.firebaseapp.com",
    databaseURL: "https://amrgharieb-a9d6e-default-rtdb.firebaseio.com",
    projectId: "amrgharieb-a9d6e",
    storageBucket: "amrgharieb-a9d6e.appspot.com",
    messagingSenderId: "338636038219",
    appId: "1:338636038219:web:680cb237ced60ba17cedce",
    measurementId: "G-ESDR68KMX9"
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
