<footer class="mh-footer mh-footer-3" id="mh-contact">
    <div class="container-fluid">
        <div class="row section-separator">
            <!-- <div class="col-sm-12 section-title wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                <h3>Contact Me</h3>
            </div> -->
            <div class="map-image image-bg col-sm-12">
                <div class="container mt-30">
                    <div class="row">
                        <!-- <div class="col-sm-12 col-md-6 mh-footer-address">
                            <div class="col-sm-12 xs-no-padding">
                                <div class="mh-address-footer-item dark-bg shadow-1 media wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                    <div class="each-icon">
                                        <i class="fa fa-location-arrow"></i>
                                    </div>
                                    <div class="each-info media-body">
                                        <h4>Address</h4>
                                        <address>
                                            Cairo
                                            <br>
                                            Egypt
                                        </address>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 xs-no-padding">
                                <div class="mh-address-footer-item media dark-bg shadow-1 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                    <div class="each-icon">
                                        <i class="fa fa-envelope-o"></i>
                                    </div>
                                    <div class="each-info media-body">
                                        <h4>Email</h4>
                                        <a href="mailto:amr@amrgharieb.com">amr@amrgharieb.com</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 xs-no-padding">
                                <div class="mh-address-footer-item media dark-bg shadow-1 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                    <div class="each-icon">
                                        <i class="fa fa-phone"></i>
                                    </div>
                                    <div class="each-info media-body">
                                        <h4>Phone</h4>
                                        <a href="callto:+20-100-394-4053">+20-100-394-4053</a><br>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">

                            <app-contact-me>
                            </app-contact-me>
                        </div> -->

                        <div class="col-sm-12 mh-copyright wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="text-left text-xs-center">
                                        <p>All right reserved to Amr Gharieb @ 2021
                                        </p>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <ul class="social-icon" style="font-size: large;">
                                        <li><a href="https://twitter.com/Amro_Gharieb"><i
                                                    class="fa fa-twitter"></i></a>
                                        </li>
                                        <li><a href="https://www.linkedin.com/in/amr-gharieb-pmp-47706368/"><i
                                                    class="fa fa-linkedin"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>