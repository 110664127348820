<section class="mh-testimonial" id="mh-testimonial">
  <div class="home-v-img">
    <div class="container">
      <div class="row section-separator" style="padding-bottom: 0px!important;;">
        <div
          class="col-sm-12 section-title wow fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
        >
          <h3>Client Reviews</h3>
        </div>
      </div>

      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let i of clients">
          <app-one-testimonial></app-one-testimonial>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section>
