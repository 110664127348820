import { Component, OnInit } from "@angular/core";
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { stripHtml } from "src/app/root/shared/functions";
import { AuthService } from "../../../Authentication/login/auth.service";
import { AppUser } from "../../../Authentication/login/user.model";
import { PostService } from "../../add-post/post.service";
import { Post } from "../../models/Post.model";

@Component({
  selector: "app-post-preview",
  templateUrl: "./post-preview.component.html",
  styleUrls: ["./post-preview.component.css"],
})
export class PostPreviewComponent implements OnInit {
  id: string;
  post$: Observable<Post>;
  user$: Observable<AppUser>;

  constructor(
    private route: ActivatedRoute,
    private service: PostService,
    public sanitized: DomSanitizer,
    private titleService: Title,
    private router: Router,
    private authService: AuthService,
    private meta: Meta
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    this.post$ = this.service.getPost(this.id).pipe(
      tap((x: Post) => {
        this.titleService.setTitle(x.title);
        this.meta.updateTag({
          name: "description",
          content: stripHtml(x.post).substring(0, 250),
        });
      })
    );

    this.user$ = this.authService.appUsers;
  }

  deletePost() {
    if (!confirm("Are you sure you want to delete this article")) return;
    this.service.delete(this.id);
    this.router.navigate(["/posts"]);
  }
}
