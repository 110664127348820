import * as $ from 'jquery';


import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './root/home/home.component';
import { PagesComponent } from './root/pages/pages.component';
import { LandingPageComponent } from './root/home/landing-page/landing-page.component';
import { TopbarComponent } from './root/home/landing-page/topbar/topbar.component';
import { BottomfooterComponent } from './root/home/landing-page/bottomfooter/bottomfooter.component';
import { HomePageComponent } from './root/pages/home-page/home-page.component';
import { AboutComponent } from './root/pages/home-page/about/about.component';
import { FeatureProjectComponent } from './root/pages/home-page/feature-project/feature-project.component';
import { SkillsComponent } from './root/pages/home-page/skills/skills.component';
import { ExperienceComponent } from './root/pages/home-page/experience/experience.component';
import { PortfolioComponent } from './root/pages/home-page/portfolio/portfolio.component';
import { PricingComponent } from './root/pages/home-page/pricing/pricing.component';
import { BlogComponent } from './root/pages/home-page/blog/blog.component';
import { TestimonialComponent } from './root/pages/home-page/testimonial/testimonial.component';
import { ContactMeComponent } from './root/pages/home-page/contact-me/contact-me.component';
import { OneTestimonialComponent } from './root/pages/home-page/testimonial/one-testimonial/one-testimonial.component';
import { ProjectComponent } from './root/pages/home-page/feature-project/project/project.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { IntroComponent } from './root/pages/home-page/intro/intro.component';
import { WhatIDoComponent } from './root/pages/home-page/what-ido/what-ido.component';
import { ClientsComponent } from './root/pages/home-page/clients/clients.component';
import { PostsComponent } from './root/pages/home-page/posts/posts.component';
import { InterestedToWorkComponent } from './root/pages/home-page/interested-to-work/interested-to-work.component';
import { AddPostComponent } from './root/pages/Blog/add-post/add-post.component';
import { QuillModule } from 'ngx-quill';
import { FormsModule, NgForm } from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFirestoreModule, AngularFirestoreDocument } from '@angular/fire/Firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlogPostsComponent } from './root/pages/Blog/blog-posts/blog-posts.component';
import { PostComponent } from './root/pages/Blog/blog-posts/post/post.component';
import { PostPreviewComponent } from './root/pages/Blog/blog-posts/post-preview/post-preview.component';
import { LoginComponent } from './root/pages/Authentication/login/login.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RestrictedAreaComponent } from './root/pages/Authentication/restricted-area/restricted-area.component';
import { StockComponent } from './root/pages/embed/stock/stock.component';





@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PagesComponent,
    LandingPageComponent,
    TopbarComponent,
    BottomfooterComponent,
    HomePageComponent,
    AboutComponent,
    FeatureProjectComponent,
    SkillsComponent,
    ExperienceComponent,
    PortfolioComponent,
    PricingComponent,
    BlogComponent,
    TestimonialComponent,
    ContactMeComponent,
    OneTestimonialComponent,
    ProjectComponent,
    IntroComponent,
    WhatIDoComponent,
    ClientsComponent,
    PostsComponent,
    InterestedToWorkComponent,
    AddPostComponent,
    BlogPostsComponent,
    PostComponent,
    PostPreviewComponent,
    LoginComponent,
    RestrictedAreaComponent,
    StockComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    CarouselModule,
    FroalaEditorModule.forRoot(),
     FroalaViewModule.forRoot(),
     AngularFireModule.initializeApp(environment.firebaseConfig),
     AngularFireDatabaseModule,
     AngularFireAuthModule,
     AngularFireStorageModule,
     HttpClientModule,
     NgbModule,
     MatDialogModule
    
  ],
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
