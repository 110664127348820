<section class="mh-pricing" id="mh-pricing">
    <div class="">
      <div class="container">
        <div class="row section-separator">
          <div
            class="col-sm-12 section-title wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
          >
            <h3>Clients</h3>
          </div>
  
          <div class="parent">
            <div class="child">
              <img
                src="../../../../../assets/companiesLogos/apache.png"
                alt="Apache"
              />
            </div>
            <div class="child">
              <img
                src="../../../../../assets/companiesLogos/kee.png"
                alt="Kuwait Energy"
              />
            </div>
            <div class="child">
              <img
                src="../../../../../assets/companiesLogos/Pharos Logo.png"
                alt="Pharos Energy"
              />
            </div>
            <div class="child">
              <img
              src="../../../../../assets/companiesLogos/kpc.png"
              alt="Khalda Petroleum Company"
            />
            </div>
            <div class="child">
              <img
              src="../../../../../assets/companiesLogos/hoanglong.png"
              alt="Hoang Long Viet Nam Joint Venture Company"
            />
            </div>
            <div class="child">
              <img
              src="../../../../../assets/companiesLogos/hoanvu.png"
              alt="Hoan Vu Viet Nam Joint Venture Company"
            />
            </div>
            <div class="child">
              <img
              src="../../../../../assets/companiesLogos/Bp.jpg"
              alt="Burg El Arab Egyptian Joint Venture Company"
            />
            </div>
            <div class="child">
              <img
              src="../../../../../assets/companiesLogos/petrosilah.jpg"
              alt="Petrosilah Egyptian Joint Venture Company"
            />
            </div>
          </div>
  
          <!-- <div class="col-sm-12 col-md-4">
                      <div class="mh-pricing dark-bg shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                          <i class="fa fa-hourglass"></i>
                          <h4>Hourley work</h4>
                          <p>I am available for Hourley projets
                          </p>
                          <h5>$50</h5>
                          <ul>
                              <li>Web Development</li>
                              <li>Advetising</li>
                              <li>Game Development</li>
                              <li>Music Writing</li>
                          </ul>
                          <a href="" class="btn btn-fill">Hire
                              Me</a>
                      </div>
                  </div> -->
        </div>
      </div>
    </div>
  </section>
  