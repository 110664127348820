import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PostService } from '../add-post/post.service';
import { Post } from '../models/Post.model';

@Component({
  selector: 'app-blog-posts',
  templateUrl: './blog-posts.component.html',
  styleUrls: ['./blog-posts.component.css']
})
export class BlogPostsComponent implements OnInit {
  posts:Observable<Post[]>;

  constructor(private service:PostService,private titleService: Title) { }

  ngOnInit(): void {

    this.titleService.setTitle('Articles');

    this.posts =  this.service
                  .getPosts();
                 
            


  }

}
