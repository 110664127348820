import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { map } from "rxjs/operators";
import { AuthService } from "./auth.service";



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) { }
    canActivate() {
        return this.authService.appUsers.pipe(
            map(user => {
                if (user) return true;

                this.router.navigate(["/res"]);
                return false;
            }));
    }
}
