<section class="mh-about" id="mh-about">
  <div class="container">
    <div class="row section-separator">
      <div class="col-sm-12 col-md-6">
        <div
          class="mh-about-img shadow-2 wow fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.4s"
        >
          <img src="./assets/images/ab-img.png" alt="" class="img-fluid" />
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="mh-about-inner">
          <h2
            class="wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.1s"
          >
            About Me
          </h2>
          <p
            class="wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
          >
            Hello, I’m Amr, petroleum engineer and software developer based on
            Egypt. I have rich experience in reservoir engineering , production
            technology (operations), desktop and web development (design, 
            building and customization.)
          </p>
          <p>
            I have experience in different technologies :-          
          </p>
          <!-- <blockquote 
          >Experience mean being used in commercial projects
          not just general understaning</blockquote> -->
          <div
            class="mh-about-tag wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.3s"
          >
            <ul>
              <li><span>C#</span></li>
              <li><span>VB.NET</span></li>
              <li><span>Python</span></li>
              <li><span>Entity Framework-EF6</span></li>
              <li><span>Entity Framework-EF Core</span></li>

              <li><span>SQL Server</span></li>
              <li><span>PostgreSQL</span></li>
              <li><span>MySQL</span></li>
              <li><span>MangoDB</span></li>

              <li><span>HTML</span></li>
              <li><span>CSS</span></li>
              <li><span>Bootstrap</span></li>
              <li><span>jQuery</span></li>
              <li><span>React</span></li>

              <li><span>JavaScript</span></li>
              <li><span>TypeScript</span></li>
              <li><span>Angular</span></li>

              <li><span>ASP.NET MVC - Web API</span></li>
              <li><span>ASP.NET Core - Web API</span></li>
              <li><span>Node.js - Web API</span></li>
              <li><span>Flask - Web API</span></li>
              <li><span>GraphQL - Web API</span></li>

              
              <li><span>Dart</span></li>
              <li><span>Flutter</span></li>

              <li><span>IIS</span></li>
              <li><span>Docker</span></li>
              <li><span>Bash</span></li>

            </ul>
          </div>
          <!-- <a
            href=""
            class="btn btn-fill wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.4s"
            >Downlaod CV <i class="fa fa-download"></i
          ></a> -->
        </div>
      </div>
    </div>
  </div>
</section>
