<!--
        ===================
           Home
        ===================
        -->
<app-intro></app-intro>
<!--
            ===================
               ABOUT
            ===================
            -->
<app-about></app-about>

<!--
            ===================
               SERVICE
            ===================
            -->
<app-what-ido></app-what-ido>
<!--
            ===================
              FEATURE PROJECTS
            ===================
            -->
<!-- <app-feature-project></app-feature-project> -->

<!--
            ===================
               SKILLS
            ===================
            -->
<!-- <app-skills></app-skills> -->

<!--
            ===================
               EXPERIENCES
            ===================
            -->

<!-- <app-experience></app-experience> -->
<!--
            ===================
               PORTFOLIO
            ===================
            -->
<!-- <app-portfolio></app-portfolio> -->

<!--
     ===================
               PRICING
            ===================
            -->
<app-clients></app-clients>

<!--
            ===================
               QUATES
            ===================
            -->
<!-- <app-interested-to-work></app-interested-to-work> -->
<!--
          
            ===================
               BLOG
            ===================
            -->
<app-blog></app-blog>

<!--
            ===================
               Testimonial
            ===================
            -->
<!-- <app-testimonial></app-testimonial> -->
