<section style="margin-top: 80px">
  <div class="container">
    <div class="row section-separator">
      <div class="col-sm-12 col-md-12">
        <form
          #formData="ngForm"
          (ngSubmit)="(post.id) ? update(formData) : save(formData)"
        >
          <div class="form-row" style="margin-bottom: 20px">
            <button *ngIf="!post.id" class="btn btn-success" type="submit">Save post</button>
            <button *ngIf="post.id" class="btn btn-warning" type="submit">Update post</button>

            <button
              class="btn btn-info"
              type="button"
              (click)="editorChange()"
            >
              Preview the post
            </button>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <input
                required
                [(ngModel)]="post.title"
                name="title"
                type="text"
                class="form-control"
                id="title"
                placeholder="Title"
              />
            </div>
            <div class="form-group col-md-6">
              <select
                [(ngModel)]="post.category"
                name="category"
                id="category"
                class="form-control"
                class="form-control"
              >
                <option value="General">General</option>
                <option value="Network">Network</option>
                <option value="Programming">Programming</option>
                <option value="Tips and tricks">Tips and tricks</option>
                <option value="General">Life</option>
              </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-12">
              <textarea
                id="editor"
                (onchange)="editorChange()"
                class="form-control"
              ></textarea>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <div class="col-md-8">
                <label for="file">Choose featured image </label>
                <br />
                <input
                  ngModel
                  name="file"
                  id="file"
                  type="file"
                  #file
                  placeholder="Choose featured image"
                  (input)="onFeaturedImageSelect($event)"
                />
              </div>
              <div class="col-md-4">
                <span class="upload" *ngIf="featuredImagePercent | async">
                 upload Pecent : {{ featuredImagePercent | async }}%
                </span>
              </div>
            </div>

            <div class="form-group col-md-6">
              <img [src]="post.featuredImage" alt="" width="300px" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<div class="container">
  <div class="card">
    <div class="card-body">
      <div id="post" [innerHTML]="sanitized.bypassSecurityTrustHtml(post.post)" style="line-height: 2.5rem;"></div>
    </div>
  </div>
</div>

<ng-template #progressWindow let-modal class="model">
  <div class="modal-header"></div>

  <div class="modal-body" >
    <div class="col-xl-12 col-lg-12">
      <div class="card shadow mb-4">
        <div class="progress">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            [attr.aria-valuenow]="uploadPercent | async"
            aria-valuemin="0"
            aria-valuemax="100"
            
            [ngStyle]="{ width: (uploadPercent | async) + '%' }"
          >
            {{ uploadPercent | async | number: "1.0-0" }} %
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Save click')"
    >
      Return
    </button>
  </div>
</ng-template>

<ng-template #success let-modal class="model">
  <div class="modal-body">
    <div class="col-xl-12 col-lg-12">
      <div
        class="card shadow mb-4"
        style="
          border: 0px;
          font-size: x-large;
          color: green;
          align-items: center;
        "
      >
        The post saved successfully
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Save click')"
    >
      Return
    </button>
  </div>
</ng-template>

<ng-template #fail let-modal class="model">
  <div class="modal-body" style="overflow-y: scroll; height: 600px">
    <div class="col-xl-12 col-lg-12">
      <div class="card shadow mb-4">
        <!-- Card Body -->
        <div
          class="card-body"
          style="
            border: 0px;
            font-size: x-large;
            color: red;
            align-items: center;
          "
        >
          Error while saving
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.dismiss('Save click')"
    >
      Return
    </button>
  </div>
</ng-template>
