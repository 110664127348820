import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LandingPageComponent } from "./root/home/landing-page/landing-page.component";
import { AuthGuard } from "./root/pages/Authentication/login/auth.guard";
import { RestrictedAreaComponent } from "./root/pages/Authentication/restricted-area/restricted-area.component";
import { AddPostComponent } from "./root/pages/Blog/add-post/add-post.component";
import { BlogPostsComponent } from "./root/pages/Blog/blog-posts/blog-posts.component";
import { PostPreviewComponent } from "./root/pages/Blog/blog-posts/post-preview/post-preview.component";
import { StockComponent } from "./root/pages/embed/stock/stock.component";
import { HomePageComponent } from "./root/pages/home-page/home-page.component";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    children: [
      {
        path: "",
        component: HomePageComponent,
        data:{title:'Amr Gharieb'}
      },
      {
        path: "addPost/:id",
        canActivate:[AuthGuard],
        component: AddPostComponent,
      },
      {
        path: "addPost",
        canActivate:[AuthGuard],
        component: AddPostComponent,
        data:{title:'Add New Post'}
      },
      {
        path: "posts",
        component: BlogPostsComponent,
      },
      {
        path: "post/:id",
        component: PostPreviewComponent,
      },
      {
        path: "aux/stock",
        component: StockComponent,
      },
    ],
  },
  {
    path: "res",
    component: RestrictedAreaComponent,
    data:{title:'Restricted Area'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
