import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/root/pages/Authentication/login/auth.service';
import { LoginComponent } from 'src/app/root/pages/Authentication/login/login.component';
import { AppUser } from 'src/app/root/pages/Authentication/login/user.model';
import { updateMethodSignature } from 'typescript';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  user$:Observable<AppUser>;

  constructor(private dialog: MatDialog,private authService:AuthService) { }

  ngOnInit(): void {
    let trigger = $('.navbar-toggler'),
      overlay = $('.overlay'),
      navc = $('.navbar-collapse'),
      active = false;

    $('.navbar-toggler, .navbar-nav li a, .overlay').on('click', function () {
      $('.navbar-toggler').toggleClass('active')
      //   $('#js-navbar-menu').toggleClass('active');
      //   $('.navbar-collapse').toggleClass('show');
      overlay.toggleClass('active');
      navc.toggleClass('active');
    });


    $('li a').on('click', function () {
      let $this = $(this).parent();
      let isActive = $this.hasClass('active');
      $('li a').parent().removeClass("active");
      isActive ? $this.removeClass("active") : $this.addClass("active");
    });


   this.user$ = this.authService.appUsers;
  }

  logOut(){
    this.authService.logout();

  }
  login(){

    const dialogRef = this.dialog.open(LoginComponent, {
      width: "700px",
      data: {
        
        
      },
    });


  }

}
