<div
  class="mh-blog-item dark-bg wow fadeInUp"
  data-wow-duration="0.8s"
  data-wow-delay="0.3s"
  style="border-top-right-radius: 20px !important"
>
  <img [src]="post.featuredImage" alt="" class="img-fluid image" />
  <div class="blog-inner">
    <h2>
      <a [routerLink]="['/post', post.id]">{{ post.title }}</a>
    </h2>
    <div class="mh-blog-post-info">
      <ul>
        <li>
          <strong>Post On</strong><a>{{ post.date | date: "dd-MMM-yyyy" }}</a>
        </li>
        <li>
          <strong>By</strong><a style="background-color: #23D3B7;padding: 3px;color: white;">{{ post.creator }}</a>
        </li>
      </ul>
    </div>
    <div class="mh-blog-post-info">
     <a style="background-color: #23D3B7;color: white;padding: 3px">{{ post.category }}</a>
    </div>
    <p>
      {{ post.sanitizedPost }}
    </p>
    <a [routerLink]="['/post', post.id]">Read More</a>
  </div>
</div>
