import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.css']
})
export class ContactMeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    (<any>$("#contactForm")).validator().on("submit", function (event) {
      if (event.isDefaultPrevented()) {
        // handle the invalid form...
        formError();
        submitMSG(false, "Did you fill in the form properly?");
      } else {
        // everything looks good!
        event.preventDefault();
        submitForm();
      }
    });

    function submitForm() {
      var name = $("#name").val();
      var email = $("#email").val();
      var message = $("#message").val();
      $.ajax({
        type: "POST",
        url: "process.php",
        data: "name=" + name + "&email=" + email + "&message=" + message,
        success: function (text) {
          if (text == "success") {
            formSuccess();
          } else {
            formError();
            submitMSG(false, text);
          }
        }
      });
    }

    function formSuccess() {
      (<any>$("#contactForm"))[0].reset();
      submitMSG(true, "Message Sent!")
    }

    function formError() {
      (<any>$("#contactForm")).removeClass().addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
        $(this).removeClass();
      });
    }

    function submitMSG(valid, msg) {
      if (valid) {
        var msgClasses = "h3 text-center fadeInUp animated text-success";
      } else {
        var msgClasses = "h3 text-center shake animated text-danger";
      }
      $("#msgSubmit").removeClass().addClass(msgClasses).text(msg);
    }


  }

}
