<section class="mh-experince image-bg featured-img-one" id="mh-experience">
    <div class="img-color-overlay">
        <div class="container">
            <div class="row section-separator">
               
                
                <div class="col-sm-12 col-md-12">
                    <div class="mh-work">
                        <h3>Work Experience</h3>
                        <div class="mh-experience-deatils parent">
                            <!-- Education Institutes-->
                            <div class="mh-work-item dark-bg wow fadeInUp child" data-wow-duration="0.8s" data-wow-delay="0.4s">
                                <h4>UI/UX Designer <a href="">IronSketch</a>
                                </h4>
                                <div class="mh-eduyear">
                                    2005-2008</div>
                                <span>Responsibility
                                    :</span>
                                <ul class="work-responsibility">
                                    <li><i class="fa fa-circle"></i>Validate CSS
                                    </li>
                                    <li><i class="fa fa-circle"></i>Project Management
                                    </li>
                                </ul>
                            </div>
                            <!-- Education Institutes-->
                            <div class="mh-work-item dark-bg wow fadeInUp child" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                <h4>Art & Multimedia From <a href="">Oxford
                                        University</a>
                                </h4>
                                <div class="mh-eduyear">
                                    2005-2008</div>
                                <span>Responsibility
                                    :</span>
                                <ul class="work-responsibility">
                                    <li><i class="fa fa-circle"></i>Validate CSS
                                    </li>
                                    <li><i class="fa fa-circle"></i>Project Management
                                    </li>
                                </ul>
                            </div>
                            <!-- Education Institutes-->
                            <div class="mh-work-item dark-bg wow fadeInUp child" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                <h4>Art & Multimedia From <a href="">Oxford
                                        University</a>
                                </h4>
                                <div class="mh-eduyear">
                                    2005-2008</div>
                                <span>Responsibility
                                    :</span>
                                <ul class="work-responsibility">
                                    <li><i class="fa fa-circle"></i>Validate CSS
                                    </li>
                                    <li><i class="fa fa-circle"></i>Project Management
                                    </li>
                                </ul>
                            </div>
                            <div class="mh-work-item dark-bg wow fadeInUp child" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                <h4>Art & Multimedia From <a href="">Oxford
                                        University</a>
                                </h4>
                                <div class="mh-eduyear">
                                    2005-2008</div>
                                <span>Responsibility
                                    :</span>
                                <ul class="work-responsibility">
                                    <li><i class="fa fa-circle"></i>Validate CSS
                                    </li>
                                    <li><i class="fa fa-circle"></i>Project Management
                                    </li>
                                </ul>
                            </div>

                            <div class="mh-work-item dark-bg wow fadeInUp child" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                <h4>Art & Multimedia From <a href="">Oxford
                                        University</a>
                                </h4>
                                <div class="mh-eduyear">
                                    2005-2008</div>
                                <span>Responsibility
                                    :</span>
                                <ul class="work-responsibility">
                                    <li><i class="fa fa-circle"></i>Validate CSS
                                    </li>
                                    <li><i class="fa fa-circle"></i>Project Management
                                    </li>
                                </ul>
                            </div>

                            <div class="mh-work-item dark-bg wow fadeInUp child" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                <h4>Art & Multimedia From <a href="">Oxford
                                        University</a>
                                </h4>
                                <div class="mh-eduyear">
                                    2005-2008</div>
                                <span>Responsibility
                                    :</span>
                                <ul class="work-responsibility">
                                    <li><i class="fa fa-circle"></i>Validate CSS
                                    </li>
                                    <li><i class="fa fa-circle"></i>Project Management
                                    </li>
                                </ul>
                            </div>

                            <div class="mh-work-item dark-bg wow fadeInUp child" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                <h4>Art & Multimedia From <a href="">Oxford
                                        University</a>
                                </h4>
                                <div class="mh-eduyear">
                                    2005-2008</div>
                                <span>Responsibility
                                    :</span>
                                <ul class="work-responsibility">
                                    <li><i class="fa fa-circle"></i>Validate CSS
                                    </li>
                                    <li><i class="fa fa-circle"></i>Project Management
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
