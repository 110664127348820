<section class="mh-featured-project image-bg featured-img-one" id="mh-projects">
    <div class="img-color-overlay">
        <div class="container">
            <div class="row section-separator">
                <div class="section-title col-sm-12">
                    <h3>Featured Projects</h3>
                </div>
                <div class="col-sm-12">
                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide *ngFor="let i of projects">
                          <app-project  tagName="Desktop & Web Development" 
                          projectName=OMS
                          image='./assets/images/p-2.png' 
                          brief='Stamp is a clean and elegant Multipurpose Landing Page Template. It will fit perfectly for Startup, Web App or any type of Web Services. It has 4 background styles with 6 homepage styles. 6 pre-defined color scheme. All variations are organized separately
                          so you can use / customize the template very easily.'
                          taxnomies='Design & Development'
                          ></app-project>
                        </ng-template>
                      </owl-carousel-o>
                </div>
            </div>
            <!-- End: .row -->
        </div>
    </div>
</section>