import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from "@angular/fire/Firestore";
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Post } from "../models/Post.model";

@Injectable({
  providedIn: "root",
})
export class PostService {
  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    private storage: AngularFireStorage
  ) {}

  async dataUrlToFile(
    dataUrl: string,
    fileName: string,
    contentType: string
  ): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: contentType });
  }

  savePost(post: Post): Promise<DocumentReference> {
    return this.afs.collection("posts").add(post);
  }
  update(data: Post, id) {
    return this.afs.collection("posts").doc(id).update(data);
  }
  delete(id:string) {
    return this.afs.doc("posts/" + id).delete();
  }
  getPost(id: string) {
    return this.afs
      .doc("posts/" + id)
      .snapshotChanges()
      .pipe(
        map((a) => {
          const data = a.payload.data() as any;
          const id = a.payload.id;
          // data.createdOn = data.date.seconds * 1000;
          return { id, ...data };
        })
      );

    // return this.afs.doc("posts/" + id).valueChanges();
  }

  getPosts(articleNo?:number): Observable<Post[]> {

    if(articleNo){
      return this.afs
      .collection<Post>("posts", (ref) => ref.orderBy("createdOn").limit(3))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            // data.createdOn = data.date.seconds * 1000;
            return { id, ...data };
          })
        )
      );
    }
    if(!articleNo){
      return this.afs
      .collection<Post>("posts", (ref) => ref.orderBy("createdOn"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            // data.createdOn = data.date.seconds * 1000;
            return { id, ...data };
          })
        )
      );
    }
    
  }
}
