<section class="mh-quates">
    <div class="img-color-overlay">
      <div class="container">
        <div class="row section-separator">
          <div class="each-quates">
            <blockquote>
              <h3
                class="wow fadeInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
              >
                Interested to Work?
              </h3>
              <p
                class="wow fadeInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.4s"
              >
                You can use the below contact form or direct contact via the phone
                no. in the top of the page
              </p>
              <a
                href="#mh-contact"
                class="btn btn-fill wow fadeInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.5s"
                >Contact</a
              >
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </section>
  