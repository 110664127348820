<div class="white-vertion black-bg">

    <!-- Start Loader -->
    <!-- <div class="section-loader">
        <div class="loader">
            <div></div>
            <div></div>
        </div>
    </div> -->
    <!-- End Loader -->

    <!--
        ===================
           NAVIGATION
        ===================
        -->
    <app-topbar></app-topbar>

    <!--
        ===================
           Router Link
        ===================
        -->
    <router-outlet></router-outlet>

    <!--
        ===================
           FOOTER 3
        ===================
        -->
    <app-bottomfooter></app-bottomfooter>


</div>