<section class="mh-service image-bg home-2-img" id="mh-service">
  <div class="img-foverlay img-color-overlay">
    <div class="container">
      <div class="row section-separator">
        <div
          class="col-sm-12 text-center section-title wow fadeInUp"
          data-wow-duration="0.8s"
          data-wow-delay="0.2s"
        >
          <h2>What I do</h2>
        </div>
        <div class="col-sm-4">
          <div
            class="mh-service-item shadow-1 dark-bg wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.3s"
          >
            <i class="fa fa-bullseye purple-color"></i>
            <h3>Petroleum Engineering</h3>
            <p>
              With my wide experience in operation and reservoir engineering can
              handle any issue or request in an efficient way
            </p>
          </div>
        </div>
        <div class="col-sm-4">
          <div
            class="mh-service-item shadow-1 dark-bg wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.5s"
          >
            <i class="fa fa-code iron-color"></i>
            <h3>Databases Design</h3>
            <p>
              Determines what data must be stored and how the data elements
              interrelate. whatever the database kind either SQL or NoSQL
            </p>
          </div>
        </div>
        <div class="col-sm-4">
          <div
            class="mh-service-item shadow-1 dark-bg wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.7s"
          >
            <i class="fa fa-object-ungroup sky-color"></i>
            <h3>Desktop Development</h3>
            <p>
              Using .Net framework to deliver robust applications with neat
              visualizations and high performance
            </p>
          </div>
        </div>
      </div>
      <div class="row" style="margin-bottom: 20px">
        <div class="col-sm-4">
          <div
            class="mh-service-item shadow-1 dark-bg wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.3s"
          >
            <i class="fa fa-tachometer" style="color: #e83e8c"></i>
            <h3>Web Development</h3>
            <p>
              Work with the latest technology in web development from
              programming to deploying
            </p>
          </div>
        </div>
        <div class="col-sm-4">
          <div
            class="mh-service-item shadow-1 dark-bg wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.5s"
          >
            <i class="fa fa-bug" style="color: black"></i>
            <h3>Mobile Development</h3>
            <p>
              Integration with the backend experience which I have will be
              reflected in the quality of the work
            </p>
          </div>
        </div>
        <div class="col-sm-4">
          <div
            class="mh-service-item shadow-1 dark-bg wow fadeInUp"
            data-wow-duration="0.8s"
            data-wow-delay="0.7s"
          >
            <i class="fa fa-cloud-download" style="color: #28a745"></i>
            <h3>UI/UX Design</h3>
            <p>
              Can Create and modify templates to match the need of customers
            </p>
          </div>
        </div>
      </div>
    </div>
    <br>
<br>
  </div>
</section>

