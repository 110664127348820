import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth) {

  }
  async saveLogin(uid, email) {

    this.db.object('/users/' + uid).valueChanges().subscribe(async user => {
      if (user === null)
        await this.db.object('/users/' + uid).update({
          userName: '',
          imageUrl: '',
          isAdmin: false,
          position: '',
          email: email,
          pin: 1234,
          uid: (await this.afAuth.currentUser).uid

        });
    });

  }
  async updatePinCode(pin: Number, uid) {

    this.db.object('/users/' + uid).valueChanges().subscribe(async user => {
      if (user === null)
        await this.db.object('/users/' + uid).update({
          pin: pin,
        });
    });

  }
  async updateUserData(uid, userName, imageUrl, position) {
    let Ouser = null;
    this.db.object('/users/' + uid).valueChanges().subscribe(user => {
      if (user === null)
        return;
      Ouser = user;
    });
    return this.db.object('/users/' + uid).update({
      userName: userName,
      imageUrl: imageUrl,
      position: position
    });

  }
  async updateUserPin(uid, pin) {
    let Ouser = null;
    this.db.object('/users/' + uid).valueChanges().subscribe(user => {
      if (user === null)
        return;
      Ouser = user;
    });
    return this.db.object('/users/' + uid).update({
      pin: pin
    });

  }
  getUser(uid): Observable<any> {
    return this.db.object('/users/' + uid).valueChanges();
  }
}
