<div class="col-sm-12 mh-featured-item">
    <div class="row">
        <div class="col-sm-7">
            <div class="mh-featured-project-img shadow-2 wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                <img [src]="image" alt="" class="img-fluid">
            </div>
        </div>
        <div class="col-sm-5">
            <div class="mh-featured-project-content">
                <h4 class="project-category wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s">
                   {{tagName}}
                </h4>
                <h2 class="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                    {{projectName}}</h2>
                <span class="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">{{taxnomies}}</span>
                <p class="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                   {{brief}}
                </p>
                <!-- <a href="" class="btn btn-fill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">View
                    Details</a>
                <div class="mh-testimonial mh-project-testimonial wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.9s">
                    <blockquote>
                        <q>Excellent
                            Template
                            -
                            suits
                            my
                            needs
                            perfectly
                            whilst
                            allowing
                            me
                            to
                            learn
                            some
                            new
                            technology
                            first
                            hand.</q>
                        <cite>-
                            Shane
                            Kavanagh</cite>
                    </blockquote>
                    <blockquote>
                        <q>Creative
                            Template
                            -
                            suits
                            my
                            needs
                            perfectly
                            whilst
                            allowing
                            me
                            to
                            learn
                            some
                            new
                            technology
                            first
                            hand.</q>
                        <cite>-
                            Shane
                            Kavanagh</cite>
                    </blockquote>
                    <blockquote>
                        <q>Organize
                            Code
                            -
                            suits
                            my
                            needs
                            perfectly
                            whilst
                            allowing
                            me
                            to
                            learn
                            some
                            new
                            technology
                            first
                            hand.</q>
                        <cite>-
                            Shane
                            Kavanagh</cite>
                    </blockquote>
                </div> -->
            </div>
        </div>
    </div>
</div>