<section class="mh-skills" id="mh-skills">
    <div class="home-v-img">
        <div class="container">
            <div class="row section-separator">
                <div class="section-title text-center col-sm-12">
                    <!--<h2>Skills</h2>-->
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="mh-skills-inner">
                        <div class="mh-professional-skill wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                            <h3>Technical Skills</h3>
                            <div class="each-skills">
                                <div class="candidatos">
                                    <div class="parcial">
                                        <div class="info">
                                            <div class="nome">
                                                Javascript
                                            </div>
                                            <div class="percentagem-num">
                                                86%
                                            </div>
                                        </div>
                                        <div class="progressBar">
                                            <div class="percentagem" style="width: 86%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="candidatos">
                                    <div class="parcial">
                                        <div class="info">
                                            <div class="nome">
                                                Java
                                            </div>
                                            <div class="percentagem-num">
                                                46%
                                            </div>
                                        </div>
                                        <div class="progressBar">
                                            <div class="percentagem" style="width: 46%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="candidatos">
                                    <div class="parcial">
                                        <div class="info">
                                            <div class="nome">
                                                Python
                                            </div>
                                            <div class="percentagem-num">
                                                38%
                                            </div>
                                        </div>
                                        <div class="progressBar">
                                            <div class="percentagem" style="width: 38%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="candidatos">
                                    <div class="parcial">
                                        <div class="info">
                                            <div class="nome">
                                                PHP
                                            </div>
                                            <div class="percentagem-num">
                                                17%
                                            </div>
                                        </div>
                                        <div class="progressBar">
                                            <div class="percentagem" style="width: 17%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="candidatos">
                                    <div class="parcial">
                                        <div class="info">
                                            <div class="nome">
                                                Python
                                            </div>
                                            <div class="percentagem-num">
                                                38%
                                            </div>
                                        </div>
                                        <div class="progressBar">
                                            <div class="percentagem" style="width: 38%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="candidatos">
                                    <div class="parcial">
                                        <div class="info">
                                            <div class="nome">
                                                PHP
                                            </div>
                                            <div class="percentagem-num">
                                                17%
                                            </div>
                                        </div>
                                        <div class="progressBar">
                                            <div class="percentagem" style="width: 17%;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="mh-professional-skills wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s">
                        <h3>Professional Skills</h3>
                        <ul class="mh-professional-progress">
                            <li>
                                <div class="mh-progress mh-progress-circle" data-progress="95">
                                </div>
                                <div class="pr-skill-name">
                                    Communication</div>
                            </li>
                            <li>
                                <div class="mh-progress mh-progress-circle" data-progress="55">
                                </div>
                                <div class="pr-skill-name">
                                    Team Work</div>
                            </li>
                            <li>
                                <div class="mh-progress mh-progress-circle" data-progress="86">
                                </div>
                                <div class="pr-skill-name">
                                    Project Management
                                </div>
                            </li>
                            <li>
                                <div class="mh-progress mh-progress-circle" data-progress="60">
                                </div>
                                <div class="pr-skill-name">
                                    Creativity</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>