import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restricted-area',
  templateUrl: './restricted-area.component.html',
  styleUrls: ['./restricted-area.component.css']
})
export class RestrictedAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
