import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { PostService } from '../../Blog/add-post/post.service';
import { Post } from '../../Blog/models/Post.model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  posts:Observable<Post[]>;
  constructor(private service:PostService,private titleService: Title) { }

  ngOnInit(): void {
    this.posts =  this.service
    .getPosts(3);
   
  }

}
